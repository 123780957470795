h2 {
    text-align: center;
}

.main-menu-image {
    height: 13rem;
    object-fit: contain;
}

.main-menu-title {
    align-self: center;
    width: 100%;
}

.main-menu-container {
    position: relative;
    padding: 1rem;
    padding-top: 2rem;
    height: 100%;
}
