.order-item-container {
    padding: 0.5rem;
}

.title-wrapper {
    display: flex;
    .order-item {
        display: flex;
        margin-bottom: 1rem;
        img.order-item-image {
            object-fit: contain;
            height: 3rem;
            border-radius: 4px;
            margin-right: 1rem;
        }
    }
}

.deli-order-itemlist {
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
    li {
        margin-bottom: 0.5rem;
        margin-right: 2rem;
    }
    li.quantity {
        min-width: 45px;
        text-align: center;
    }
    li.title {
        width: 250px;
    }
    li.price {
        text-align: center;
        margin-right: 0;
    }
}
