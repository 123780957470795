.price-summary-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    .total-container {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
        p {
            font-weight: 700;
        }
    }
}
