.btn-backspace {
    // background-image: url('../../../../public/resources/assets/images/common/7eleven-logo.png');
    // background-repeat: no-repeat;
    // background-size: contain;
    // background-position: center;
    width: 4.5rem !important;
}

.btn-enter {
    width: 7rem !important;
}

.btn-domain-name {
    width: 4.5rem !important;
}
.btn-domain-name1 {
    width: 6rem !important;
}

.btn-clear {
    width: 4.5rem !important;
}

.btn-space {
    width: 13rem !important;
}

.btn-empty-block {
    visibility: hidden;
}
