.banner-container {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: #008060;
    color: white;
}
img.banner-logo {
    margin-right: 1.6rem;
    object-fit: contain;
    max-height: 3rem;
}
