*,
body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.main-wrapper {
    display: flex;
    margin: 0.5rem;
    margin-top: 1rem;

    .route-components {
        flex: 1;
        margin: 0.5rem;
        margin-top: 1rem;
    }
}

.spacer {
    flex: 1 1 auto;
}
