.order-item-container {
    padding: 2rem;
}

.quantity-wrapper {
    display: flex;
    align-items: center;
}
ul.eleven-order-itemlist {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    li:nth-child(1) {
        margin-right: 1rem;
    }
    li:nth-child(2) {
        width: 170px;
        margin-right: 1rem;
    }
}
