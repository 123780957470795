.order-item-container {
    padding: 0.5rem !important;
}

.title-wrapper {
    display: flex;
    .order-item {
        display: flex;
        margin-bottom: 1rem;
        img.order-item-image {
            object-fit: contain;
            height: 40px;
            border-radius: 4px;
            margin-right: 1rem;
            width: 70px;
        }
    }
}
