.sidebar-menu {
    img.side-menu-img {
        max-width: 100%;
        object-fit: contain;
    }

    .deli-sidemenu-image {
        position: relative;
        right: -3rem;
        bottom: 10px;
    }
}

img.logo {
    max-width: 100%;
    object-fit: contain;
    margin-bottom: 1rem;
}

.active-button {
    background: #008000c2;
    border: solid #ec9c71 5px;
}
